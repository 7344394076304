import {CheckTargetLinkProps, withLinkSecurityCheck} from "@shared/security/SecurityCheck"
import React, {ReactNode, useEffect} from 'react'

interface LinkProps {
    elementType: string
    children?: ReactNode
    [prop: string]: any
}

const CheckedLink = withLinkSecurityCheck(_CheckedLink)
export default CheckedLink

function _CheckedLink({
    onClick, isCheckComplete,
    elementType, children, ...props
}: LinkProps & CheckTargetLinkProps) {

    // elementType が a でないケースで SMS 認証を要求するケースがたまたまないので成り立っている
    const linkUrl = elementType.toLowerCase() === 'a' ? props['href'] : undefined

    useEffect(() => {
        if (isCheckComplete) {
            location.href = linkUrl
        }
    }, [isCheckComplete])

    const hookedElement = React.createElement(elementType, {
        ...props,
        onClick,
    }, children)

    return <span className="checked-link">
        {hookedElement}
    </span>
}
