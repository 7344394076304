import React from 'react'
import ReactModal from "react-modal"

export const StyledReactModalDefaultStyles : ReactModal.Styles  = {
    content: {
        verticalAlign: 'middle',
        position: 'absolute',
        inset: '50% auto auto 50%',
        transform: 'translate(-50%,-50%)',
        zIndex: 999999,
        maxWidth: '500px',
        boxSizing: 'border-box',
        width: '90%',
        background: '#fff',
        padding: 0,
        border: 'none',
        borderRadius: '8px',
        boxShadow: '0 0 10px #000',
        textAlign: 'left',
    },
    overlay: {
        backgroundColor: 'rgba(0,0,0,0.3)',
        zIndex: 999999,
    },
}

export default function StyledReactModal(props: ReactModal.Props) {
    return React.createElement(ReactModal, {
        style: StyledReactModalDefaultStyles,
        ...props,
    })
}
