require('./css/_index.css');

// react
const React = require('react');
const ReactDOM = require('react-dom');
const ReactDOMClient = require('react-dom/client');
window.React = React;
window.ReactDOM = ReactDOM;
window.ReactDOMClient = ReactDOMClient;

// react-modal
const ReactModal = require('react-modal');
ReactModal.setAppElement('#contents');
window.ReactModal = ReactModal;

window.ReactFontAwesome = require('@fortawesome/react-fontawesome');

require('./js/jquery.diff-log-abuse-report');
require('./js/jquery.security-check-interrupt');

import './js/fonts'
