import {CheckTargetFormProps} from '@shared/security/SecurityCheck'
import {ReactNode, RefObject, useEffect, useRef, useState} from 'react'

type NormalFormProps = {
    children?: ReactNode
    [prop: string]: any
}

export default function SecurityCheckForm({
    onSubmit, isCheckComplete,
    children, formRef, ...props
}: NormalFormProps & CheckTargetFormProps) {
    if (!formRef) {
        formRef = useRef<HTMLFormElement>()
    }
    const submitterData = useSubmitEmulation(formRef)

    useEffect(() => {
        if (isCheckComplete) {
            formRef.current?.submit()
        }
    }, [isCheckComplete])

    return <form onSubmit={onSubmit} ref={formRef} {...props}>
        {children}
        {submitterData && <input type="hidden" name={submitterData.name} value={submitterData.value} /> }
    </form>
}

export function useSubmitEmulation(formRef: RefObject<HTMLFormElement>) {
    // form.submit() のときクリックしたサブミットボタンの情報が消えるので、
    // ダミーの input[type=hidden] を設けて最後にクリックしたボタンの情報を再現する。
    const [submitterData, setSubmitterData] = useState(null)
    useEffect(() => {
        const submitters = formRef.current.querySelectorAll('input[type=submit]')
        submitters.forEach((submitter) => {
            updateListener(submitter, 'click', () => {
                const name = submitter.getAttribute('name')
                const value = submitter.getAttribute('value')
                if (name && value) {
                    setSubmitterData({name, value})
                }
            }, 'checkedFormValueSender')
        })
    })

    return submitterData
}

function updateListener(target: EventTarget, eventType: string, listener: EventListener, key: string) {
    if (target[key]) {
        target.removeEventListener(eventType, target[key])
    }
    target.addEventListener(eventType, listener)
    target[key] = listener
}
