import React, {useEffect, useRef} from 'react'

type ExistingDOMWrapperProps = {
    childNodes: ChildNode[]
    type?: string | null
    [props: string]: any
};
export default function ExistingDOMWrapper({childNodes, type = null, ...props}: ExistingDOMWrapperProps) {
    const ref = useRef<HTMLElement>()
    useEffect(() => {
        childNodes.forEach((node) => {
            if (node.parentNode === ref.current) {
                return
            }
            ref.current.appendChild(node)
        })
    })
    // @ts-ignore
    return React.createElement(type ?? 'div', {ref, ...props})
}

ExistingDOMWrapper.wrap = (childNodes: ChildNode[], type: string | null = null, props = {}) => {
    return React.createElement(ExistingDOMWrapper, {childNodes: childNodes, type, ...props})
}
