import {useState} from 'react'
import {StoreApi, UseBoundStore} from 'zustand'
import {StateCreator} from "zustand/vanilla"
import SmsAuthModal, {smsAuthStoreCreatorFrom} from "@shared/security/SmsAuthModal"
import StyledReactModal from "@shared/util/StyledReactModal"
import useLocalStateUseStore from "@shared/util/zustand-hook"

export type PreCheckFunctionType = (next: Function, failed: Function) => Promise<void>
export type SendFunctionType = (category: string, comment: string, succeeded: Function, failed: (error: string) => void) => Promise<void>

type DialogState = 'closed' | 'reporting' | 'completed'

interface AbuseReportState {
    reportFinished: boolean
    dialogState: DialogState
    category: string
    comment: string
    lastError: string | null
    setCategory(category: string)
    setComment(comment: string)
    startReporting(onSmsAuthRequired: Function)
    submitReport()
    close()
}

function abuseReportStoreCreatorFrom(
    onPreCheck: PreCheckFunctionType,
    onSend: SendFunctionType,
): StateCreator<AbuseReportState> {
    return (set, get) => ({
        reportFinished: false,
        dialogState: 'closed',
        category: "",
        comment: "",
        lastError: null,
        setCategory(category) {
            set({category})
        },
        setComment(comment) {
            set({comment})
        },
        startReporting(onSmsAuthRequired) {
            // UI ロックどうする?
            onPreCheck(() => {
                set({
                    dialogState: 'reporting',
                    category: "",
                    comment: "",
                    lastError: null,
                })
            }, () => {
                onSmsAuthRequired()
            }).catch(() => {
                alert("通信エラー")
            })
        },
        submitReport() {
            // UI ロックどうする?
            const {category, comment} = get()
            onSend(category, comment, () => {
                set({
                    dialogState: 'completed',
                    reportFinished: true,
                })
            }, (error) => {
                set({lastError: error})
            }).catch(() => {
                alert("通信エラー")
            })
        },
        close() {
            set({dialogState: 'closed'})
        },
    })
}

const smsAuthModalTitle = "通報者の確認"

const smsAuthStateCreator = smsAuthStoreCreatorFrom(window.globalParamsContainer.smsAuthParams)

export default function DiffLogAbuseReport({onPreCheck, onSend, reportReasons}: {
    onPreCheck: PreCheckFunctionType
    onSend: SendFunctionType
    reportReasons: { key: string, label: string }[]
}) {
    const useStore = useLocalStateUseStore(abuseReportStoreCreatorFrom(onPreCheck, onSend))
    const {reportFinished, dialogState, startReporting} = useStore()

    const useSmsAuthStore = useLocalStateUseStore(smsAuthStateCreator)
    const {openSmsAuthModal} = useSmsAuthStore()

    return <>
        <button className="diff-log-report-button" disabled={reportFinished} onClick={(e) => {
            e.preventDefault()
            startReporting(() => {
                openSmsAuthModal(smsAuthModalTitle)
            })
        }}>{reportFinished ? "通報済み" : "通報する"}</button>
        <SmsAuthModal useStore={useSmsAuthStore}/>
        <ReportModal useStore={useStore} isOpen={dialogState === 'reporting'} reportReasons={reportReasons}/>
        <CompleteModal useStore={useStore} isOpen={dialogState === 'completed'}/>
    </>
}

function ReportModal({useStore, reportReasons, isOpen}: {
    useStore: UseBoundStore<StoreApi<AbuseReportState>>
    reportReasons: { key: string, label: string }[]
    isOpen: boolean
}) {
    const {
        category, comment, lastError,
        setCategory, setComment,
        submitReport, close,
    } = useStore()

    const [confirmed1, setConfirmed1] = useState(false)
    const [confirmed2, setConfirmed2] = useState(false)

    const reset = () => {
        setConfirmed1(false)
        setConfirmed2(false)
        setCategory("")
        setComment("")
    }

    const submitEnabled = confirmed1 && confirmed2 && category !== ""

    return <StyledReactModal id="diff-log-report-modal" isOpen={isOpen} onAfterOpen={reset}>
        <h3 className="modal-title">通報理由の選択</h3>
        <form>
            <p>不適切な投稿として通報するには以下の「送信」ボタンを押して下さい。</p>
            <div className="form-group">
                <select name="category" id="report_category" onChange={(e) => {
                    setCategory(e.currentTarget.selectedOptions.item(0).value)
                }}>
                    <option value="" selected={category === ""}>通報理由を選択してください</option>
                    {reportReasons.map((e) => {
                        return <option value={e.key} selected={category === e.key}>{e.label}</option>
                    })}
                </select>
            </div>
            <div className="form-group">
                <textarea name="comment" id="report_comment" value={comment} onChange={(e) => {
                    setComment(e.currentTarget.value)
                }} placeholder="備考（上記通報内容に補足が必要な場合のみ記入）"/>
            </div>
            <div className="form-group">
                <label>
                    <input type="checkbox" name="terms[]" value="confirmed" checked={confirmed1} onChange={(e) => {
                        setConfirmed1(e.currentTarget.checked)
                    }}/> 通報の目的は、個人を責めることではなくコンテンツの健全化を促進するものであることを理解しています。
                </label>
            </div>
            <div className="form-group">
                <label>
                    <input type="checkbox" name="terms[]" value="confirmed" checked={confirmed2} onChange={(e) => {
                        setConfirmed2(e.currentTarget.checked)
                    }}/> 無益な通報を繰り返すと、場合によっては自分の電話番号が何らかの制限を受ける可能性があることを理解しています。
                </label>
            </div>
            <p>法令・個人情報にかかわる内容は別途お問い合わせください。<br/>（この通報内容は wiki 管理ユーザーと共有される情報のため）</p>
            <hr/>
            {lastError && <div className="error-message">{lastError}</div>}
            <div style={{textAlign: "right"}}>
                <button type="submit" disabled={!submitEnabled} onClick={(e) => {
                    e.preventDefault()
                    submitReport()
                }}>送信
                </button>
                {" "}
                <button type="button" className="close-modal" name="cancel" onClick={(e) => {
                    e.preventDefault()
                    close()
                }}>キャンセル
                </button>
            </div>
        </form>
    </StyledReactModal>
}

function CompleteModal({useStore, isOpen}: {
    useStore: UseBoundStore<StoreApi<AbuseReportState>>
    isOpen: boolean
}) {
    const {close} = useStore()

    return <StyledReactModal id="diff-log-report-complete-modal" isOpen={isOpen}>
        <h3 className="modal-title">通報完了</h3>
        <div className="modal-body">
            <p>通報しました。</p>
        </div>
        <div className="footer">
            <a className="close-modal-button" onClick={(e) => {
                e.preventDefault()
                close()
            }}>閉じる</a>
        </div>
    </StyledReactModal>
}

