import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {StoreApi, UseBoundStore} from 'zustand'
import {StateCreator} from "zustand/vanilla"
import StyledReactModal from "../util/StyledReactModal"

interface InterruptAlertState {
    isInterruptAlertOpen: boolean
    setIsInterruptAlertOpen(b: boolean): void
}

// noinspection JSUnusedLocalSymbols
export const interruptAlertStateCreator: StateCreator<InterruptAlertState> = (set, get) => ({
    isInterruptAlertOpen: false,
    setIsInterruptAlertOpen(isInterruptAlertOpen) {
        set({isInterruptAlertOpen: isInterruptAlertOpen})
    },
})

export type InterruptType = 'freeze' | 'block' | 'none'

interface InterruptAlertProps {
    interruptType: InterruptType
    unfreezeUrl: string
    useStore: UseBoundStore<StoreApi<InterruptAlertState>>
}

export default function InterruptAlert({interruptType, unfreezeUrl, useStore}: InterruptAlertProps) {
    const {
        isInterruptAlertOpen, setIsInterruptAlertOpen,
    } = useStore()

    const handleClose = e => {
        e.preventDefault()
        setIsInterruptAlertOpen(false)
    }

    return <StyledReactModal isOpen={isInterruptAlertOpen}>
        <div className="interrupt-modal">
            <h3 className="modal-title">
                {interruptType === 'freeze'
                    ? <><FontAwesomeIcon icon={['fas', 'lock']}/>ページは凍結されています</>
                    : <><FontAwesomeIcon icon={['fas', 'lock']}/>管理者によって編集が禁止されています。</>
                }
            </h3>
            <div className="modal-body">
                {interruptType === 'freeze'
                    ? <>
                        <p>編集が禁止されたページにはいっさいの変更操作ができません。</p>
                        <p><a href={unfreezeUrl} className="unfreeze-link">凍結解除はこちら</a></p>
                    </>
                    : <p>編集が禁止されたページにはいっさいの変更操作ができません。</p>
                }
            </div>
            <div className="footer">
                <a className="close-modal-button" onClick={handleClose}>閉じる</a>
            </div>
        </div>
    </StyledReactModal>
}
