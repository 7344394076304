import React from 'react'
import {createRoot} from 'react-dom/client'
import CheckedForm from './component/CheckedForm'
import CheckedLink from "./component/CheckedLink"
import ExistingDOMWrapper from "./component/ExistingDOMWrapper"

(function ($) {
    $.fn.extend({
        'securityCheckInterrupt': function (this: JQuery, options) {
            // noinspection JSUnusedAssignment
            options = $.extend({}, options)

            this.each(function (this: HTMLElement) {
                const $target = $(this)
                if (!$target.is('form,a,button')) {
                    return
                }

                const targetPage = $target.data('page') as string
                const smsParams: { checkSms: boolean, preCheckSms: boolean } = {
                    'on': {checkSms: true, preCheckSms: false},
                    'pre': {checkSms: true, preCheckSms: true},
                }[$target.data('trolling-defence')] ?? {checkSms: false, preCheckSms: false}
                const interruptParams = {
                    interruptType: $target.data('interrupt') ?? 'none',
                    unfreezeUrl: $target.data('unfreeze-url') ?? "",
                }
                const isForm = $target.is('form')
                if (
                    !isForm &&
                    !smsParams.checkSms &&
                    !smsParams.preCheckSms &&
                    interruptParams.interruptType === 'none'
                ) {
                    // 何の制約もないクリックボタンには何の加工も要らない。
                    // フォームの場合は連打防止がかかるのでつねに加工が必要。
                    return
                }

                const securityCheck = {
                    targetPage,
                    ...smsParams,
                    ...interruptParams,
                }
                const attrs = elementAttributes(this)
                const placeholderType = isForm ? 'div' : 'span'
                const placeholder = placeholderElementFor(this, placeholderType)
                const children = ExistingDOMWrapper.wrap(childNodesOf(this), placeholderType, {
                    className: "-security-check-interrupt-inner-contents"
                })
                if (isForm) {
                    createRoot(placeholder).render(React.createElement(CheckedForm, {
                        ...attrs,
                        securityCheck,
                    }, children))
                } else {
                    createRoot(placeholder).render(React.createElement(CheckedLink, {
                        elementType: this.tagName,
                        ...attrs,
                        securityCheck,
                    }, children))
                }
            })
        },
    })
})(jQuery)

function childNodesOf(element: HTMLElement): ChildNode[] {
    let nodes = []
    element.childNodes.forEach((node) => {
        nodes.push(node)
    })
    return nodes
}

type AttributesMap = Record<string, string | Record<string, string>>

function elementAttributes(element: HTMLElement): AttributesMap {
    let attrs = {} as AttributesMap
    for (let i = 0; i < element.attributes.length; i++) {
        let attr = element.attributes.item(i)
        // FIXME specified を参照しなくていいならそうする
        if (attr.specified) {
            if (attr.name === 'class') {
                attrs.className = attr.value
            } else if (attr.name === 'style') {
                attrs.style = elementStyles(element)
            } else {
                attrs[attr.name] = attr.value
            }
        }
    }
    return attrs
}

function elementStyles(element: HTMLElement): Record<string, string> {
    let styles = {} as Record<string, string>
    for (let i = 0; i < element.style.length; i++) {
        let style = element.style.item(i)
        let camelCase = style.replace(/-([a-z])/g, (g) => g[1].toUpperCase())
        styles[camelCase] = element.style[style]
    }
    return styles
}

function placeholderElementFor(element: HTMLElement, tag: string): HTMLElement {
    const $target = jQuery(element)
    const $placeholder = jQuery('<' + tag + '>')
    $placeholder.insertAfter($target)
    $target.hide()
    return $placeholder.get(0)
}
