import { library,dom } from '@fortawesome/fontawesome-svg-core'
import {
    faFastBackward as faFastBackwardFreeSolid,
    faCaretLeft as faCaretLeftFreeSolid,
    faCaretRight as faCaretRightFreeSolid,
    faCaretDown as faCaretDownFreeSolid,
    faStepBackward as faStepBackwardFreeSolid,
    faStepForward as faStepForwardFreeSolid,
    faFilter as faFilterFreeSolid,
    faEdit as faEditFreeSolid,
} from '@fortawesome/free-solid-svg-icons'
import {
    faExternalLink as faExternalLinkProRegular,
} from '@fortawesome/pro-regular-svg-icons'

library.add(
    faExternalLinkProRegular, // ??
    faFastBackwardFreeSolid,
    faCaretLeftFreeSolid,
    faCaretRightFreeSolid,
    faCaretDownFreeSolid,
    faStepBackwardFreeSolid,
    faStepForwardFreeSolid,
    faFilterFreeSolid,
    faEditFreeSolid,
)

dom.watch()
