import Cookies from "js-cookie"
import React from 'react'
import {createRoot} from 'react-dom/client'
import DiffLogAbuseReport, {PreCheckFunctionType, SendFunctionType} from "./component/DiffLogAbuseReport"

(function ($) {
    $.fn.extend({
        "diffLogAbuseReport": function (this: JQuery, options: Record<string, any>) {
            options = $.extend({
                reportReasons: [
                    {key: "none", label: "なし"},
                ],
                reportPreCheckUrl: "",
                reportSendUrl: "",
            }, options)

            this.each(function (this: HTMLElement) {
                const did = $(this).data('did')
                createRoot(this).render(React.createElement(DiffLogAbuseReport, {
                    onPreCheck: createPreCheckFunction(options.reportPreCheckUrl),
                    onSend: createSendFunction(options.reportSendUrl, did),
                    reportReasons: options.reportReasons,
                }))
            })
        },
    })
})(jQuery)

function createPreCheckFunction(url: string): PreCheckFunctionType {
    return async (next, failed) => {
        const response = await fetch(url, {
            method: 'POST',
        })
        if (!response.ok) {
            failed()
        }
        const data = await response.json()
        if (!!data.result && !!Cookies.get('authkey')) {
            next()
        } else {
            failed()
        }
    }
}

function createSendFunction(url: string, did: string): SendFunctionType {
    return async (category, comment, succeeded, failed) => {
        const formData = new FormData()
        formData.append('did', did)
        formData.append('category', category)
        formData.append('comment', comment)
        const response = await fetch(url, {
            method: 'POST',
            body: formData,
        })
        if (!response.ok) {
            const data = await response.json()
            let message = "エラーが発生しました。"
            if (data.result === false && typeof data.message === 'object') {
                message = ''
                for (let k in data.message) {
                    message += k + ' : ' + data.message[k]
                }
                failed(message)
                return
            }
        }
        const data = await response.json()
        if (data.result === true) {
            succeeded()
        } else {
            failed("通報結果を判定できません。")
        }
    }
}
