import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Cookies from "js-cookie"
import {StoreApi, UseBoundStore} from 'zustand'
import {StateCreator} from "zustand/vanilla"
import StyledReactModal from "../util/StyledReactModal"

export interface SmsAuthParams {
    actionTrollingDefenceCheck: string
    actionSubmitNumber: string
    actionSubmitCode: string
    cookieName: string
}

/* Sms認証、凍結/編集制限アラート */
interface SmsAuthModalState {
    isSmsAuthModalOpen: boolean
    authCompleted: boolean
    displayNumberInput: boolean
    displayCodeInput: boolean
    number: string
    code: string
    token: string
    displayFooterMessage: boolean
    displayFooterNotice: boolean
    initialModalTitle: string
    modalTitle: string
    errMsg: string
    trollingDefenceCheck(initialModalTitle: string, passedAction: () => void, page: string, body?: string): void
    openSmsAuthModal(initialModalTitle: string): void
    closeSmsAuthModal(): void
    setNumber(number: string): void
    setCode(code: string): void
    sendNumber(via: string): void
    sendCode(): void
    cancelNumber(): void
    cancelCode(): void
    validateTelNumber(): boolean
    validateVia(via: string): boolean
    validateCode(): boolean
    saveToken(token: string): void
}

async function asyncSendJson(url: string, data: Record<string, string>) {
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: (new URLSearchParams(data)).toString(),
    })

    return await response.json()
}

export function smsAuthStoreCreatorFrom(params: SmsAuthParams): StateCreator<SmsAuthModalState> {

    const DOMAIN = '.' + location.host

    return (set, get) => ({
        isSmsAuthModalOpen: false,
        authCompleted: false,
        displayNumberInput: true,
        displayCodeInput: false,
        number: '',
        code: '',
        token: '',
        displayFooterMessage: false,
        displayFooterNotice: false,
        initialModalTitle: '',
        modalTitle: '',
        errMsg: '',
        openSmsAuthModal(initialModalTitle) {
            set({
                initialModalTitle: initialModalTitle,
                modalTitle: initialModalTitle,
                isSmsAuthModalOpen: true,
            })
        },
        closeSmsAuthModal() {
            set({isSmsAuthModalOpen: false})
        },
        async trollingDefenceCheck(initialModalTitle, passedAction, page, body) {
            // 本文ありのときは再チェックがありえる
            if (body === undefined && get().authCompleted) {
                passedAction()
                return
            }

            let url = params.actionTrollingDefenceCheck
            let data: {page?, body?} = {}
            if (page) {
                url += '&page=' + encodeURIComponent(page)
                data.page = page
            }
            if (body) {
                data.body = body
            }

            try {
                const response = await asyncSendJson(url, data)
                if (!!response.allowed) {
                    set({authCompleted: true})
                    passedAction()
                } else {
                    get().openSmsAuthModal(initialModalTitle)
                }
            } catch (e) {
                // 「この人だけ予想外のエラーが起きる」なんてのは、リクエストが通常のブラウザから
                // 発行されていないか、もしくは異常をもたらすような cookie を持っているからと想定。
                // せめてトークンだけでも、再発行で直るかためしてもらう。
                // 異常な状態になってる人は「いくら認証しても無限に SMS 認証を要求される」となるので、
                // この反応は BAN されているのと同じ感じ。BAN された人の心理を考えると、全リセットを試す。
                // ユーザー固有の不具合ならたぶんそれで直る。(固有でないなら大騒ぎ)
                get().openSmsAuthModal(initialModalTitle)
            }
        },
        setNumber(number) {
            set({number: number})
        },
        setCode(code) {
            set({code: code})
        },
        async sendNumber(via) {
            if (!get().validateTelNumber() || !get().validateVia(via)) {
                return
            }

            try {
                const response = await asyncSendJson(params.actionSubmitNumber, {
                    number: get().number,
                    via: via,
                })

                if (response.status === 'success' && !!response.token) {
                    get().saveToken(response.token)
                    set({
                        errMsg: '',
                        token: response.token,
                        displayNumberInput: false,
                        modalTitle: '認証コードを入力してください',
                        displayFooterNotice: true,
                        displayCodeInput: true,
                    })
                } else if (response.status === 'error' && !!response.message) {
                    set({errMsg: response.message})
                } else {
                    // 200 で完了しているけどそれ以外の形式ってことはありえない。
                    // プログラムの変更が同期できてないので、ハンドルできないエラーとみなす。
                    set({errMsg: 'エラー'})
                }
            } catch (e) {
                // 400 系は起きないので 500 系と思われる。ということは
                // エラーアラートが出てるはずなので、ブラウザで現象を見る必要はないかな
                set({errMsg: '通信エラー。しばらく経ってから再度お試しください。'})
            }

        },
        async sendCode() {
            if (!get().validateCode()) {
                return
            }

            try {
                const response = await asyncSendJson(params.actionSubmitCode, {
                    code: get().code,
                    token: get().token,
                })

                if (response.status === 'success') {
                    set({
                        errMsg: '',
                        authCompleted: true,
                        displayFooterNotice: false,
                        displayCodeInput: false,
                        modalTitle: '認証に成功しました',
                        displayFooterMessage: true,
                    })
                } else if (response.status === 'error' && !!response.message) {
                    set({errMsg: response.message})
                } else {
                    // 200 で完了しているけどそれ以外の形式ってことはありえない。
                    // プログラムの変更が同期できてないので、ハンドルできないエラーとみなす。
                    set({errMsg: 'エラー'})
                }
            } catch (e) {
                // 400 系は起きないので 500 系と思われる。ということは
                // エラーアラートが出てるはずなので、ブラウザで現象を見る必要はないかな
                set({errMsg: '通信エラー。しばらく経ってから再度お試しください。'})
            }
        },
        cancelNumber() {
            set({isSmsAuthModalOpen: false})
            setTimeout(() => {
                set({errMsg: ''})
            }, 500)
        },
        cancelCode() {
            set({isSmsAuthModalOpen: false})
            setTimeout(() => {
                set({
                    displayCodeInput: false,
                    displayNumberInput: true,
                    modalTitle: get().initialModalTitle,
                    displayFooterNotice: false,
                    errMsg: '',
                })
            }, 500)
        },
        validateTelNumber() {
            const intRegex = /^0[789]\d+(-\d+)*$/
            if (get().number === '') {
                set({errMsg: '電話番号を入力してください。'})
                return false
            } else if (!get().number.match(intRegex)) {
                set({errMsg: '電話番号の形式が正しくありません。'})
                return false
            }
            return true
        },
        validateVia(via) {
            const viaRegex = /^(sms|voice)$/
            if (via === '' || !via.match(viaRegex)) {
                set({errMsg: '認証コード受け取り方法が正しくありません。'})
                return false
            }
            return true
        },
        validateCode() {
            const codeRegex = /^[0-9]{6}$/

            if (get().code === '') {
                set({errMsg: '認証コードを入力してください。'})
                return false
            } else if (!get().code.match(codeRegex)) {
                set({errMsg: '認証コードの形式が正しくありません。'})
                return false
            }
            return true
        },
        saveToken(token) {
            // この書き込内容は PHP がサーバーサイドでも使うので
            // setcookie で起きる urlencode を再現する必要がある

            // noinspection JSUnusedLocalSymbols
            const phpCompatCookieWriter = Cookies.withConverter({
                write: function (value, name) {
                    return encodeURIComponent(value as string).replace(/%20/g, '+')
                },
            })

            phpCompatCookieWriter.set(params.cookieName, token, {
                // 旧 DiffAna がこの形で共有してしまっているので、これも
                // サブドメインを含む必要がある
                'domain': DOMAIN,
                'expires': 180,
            })

            // 過去に書かれたことのある形式。もし偶然こっちが使われたらアウトなので、
            // 上書きしておく。domain が微妙に違うだけなので、どっちが優先か保証ない。
            phpCompatCookieWriter.set(params.cookieName, token, {
                'expires': 180,
            })

            // cookie.js は pasth=/ がデフォなのでらくちん
        },
    })
}

interface SmsAuthModalProps {
    useStore: UseBoundStore<StoreApi<SmsAuthModalState>>
}

export default function SmsAuthModal({useStore}: SmsAuthModalProps) {
    const SMS_AUTH_HELP_URL = 'https://wikiwiki.jp/pp/sms'
    const {
        isSmsAuthModalOpen, closeSmsAuthModal,
        displayNumberInput, displayCodeInput,
        number, setNumber,
        code, setCode,
        displayFooterMessage, displayFooterNotice,
        modalTitle, errMsg,
        sendNumber, sendCode, cancelNumber, cancelCode,
    } = useStore()

    const submitNumber = e => {
        e.preventDefault()
        sendNumber(e.currentTarget.value)
    }

    const submitCode = e => {
        e.preventDefault()
        sendCode()
    }

    const handleCancelNumber = e => {
        e.preventDefault()
        cancelNumber()
    }

    const handleCancelCode = e => {
        e.preventDefault()
        cancelCode()
    }

    return <StyledReactModal isOpen={isSmsAuthModalOpen} overlayClassName={{
        base: 'sms-auth-modal-overlay',
        afterOpen: 'sms-auth-modal-overlay--after-open',
        beforeClose: 'sms-auth-modal-overlay--before-close',
    }} closeTimeoutMS={100}>
        <div className="sms-form">
            <h3 className="modal-title">{modalTitle}</h3>
            {displayNumberInput &&
                <form className="number-input">
                    <p>
                        続けるにはSMS認証が必要です。電話番号を入力し下のボタンを押すと、アクセス許可用の認証コードが送られます。
                        <a href={SMS_AUTH_HELP_URL} target="_blank" rel="noopener">
                            <FontAwesomeIcon icon={['fas', 'question-circle']}/>
                        </a>
                    </p>
                    <div>
                        <input name="number" className="form-control" pattern="\d*"
                               onChange={e => setNumber(e.currentTarget.value)}
                               value={number}
                               placeholder="電話番号を入力してください 例: 090xxxxxxxx"/>
                        {errMsg &&
                            <p className="errorMsg">{errMsg}</p>
                        }
                    </div>
                    <div className="button-container">
                        <button type="submit" className="submit-btn primary" name="via" value="sms"
                                onClick={submitNumber}> SMSに認証コードを送る
                        </button>
                        <br/>
                        <button type="submit" className="submit-btn secondary" name="via" value="voice"
                                onClick={submitNumber}> 電話音声で認証コードを送る
                        </button>
                        <br/>
                        <button type="button" className="close-modal" name="cancel"
                                onClick={handleCancelNumber}>キャンセル
                        </button>
                    </div>
                </form>
            }
            {displayCodeInput &&
                <form className="code-input">
                    <div>
                        <input name="code" className="form-control primary" pattern="\d*"
                               placeholder="認証コード入力 例: 000000"
                               value={code} onChange={e => setCode(e.currentTarget.value)}/><br/>
                        {errMsg &&
                            <p className="errorMsg">{errMsg}</p>
                        }
                    </div>
                    <div className="button-container">
                        <button type="submit" className="submit-btn primary" name="via" value="sms"
                                onClick={submitCode}>
                            認証
                        </button>
                        <br/>
                        <button type="button" className="close-modal" name="cancel" onClick={handleCancelCode}>
                            キャンセル
                        </button>
                    </div>
                </form>
            }
            <div className="footer">
                {displayFooterMessage &&
                    <div className="message">
                        <button type="button" className="close-modal" onClick={() => closeSmsAuthModal()}>
                            WIKIの操作を続ける
                        </button>
                    </div>
                }
                {displayFooterNotice &&
                    <p className="footerNotice">
                        認証コードが届かない場合は
                        <a href="https://wikiwiki.jp/pp/sms#04" target="_blank" rel="noopener">
                            <FontAwesomeIcon icon={['fas', 'question-circle']}/>
                        </a>
                    </p>
                }
            </div>
        </div>
    </StyledReactModal>
}
