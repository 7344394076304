import React, {FunctionComponent, MutableRefObject, PropsWithChildren, useEffect, useRef, useState} from 'react'
import InterruptAlert, {interruptAlertStateCreator, InterruptType} from "./InterruptAlert"
import SmsAuthModal, {smsAuthStoreCreatorFrom} from "./SmsAuthModal"
import useLocalStateUseStore from "../util/zustand-hook"

export interface SecurityCheckParams {
    preCheckSms: boolean
    checkSms: boolean
    targetPage: string
    interruptType: InterruptType
    unfreezeUrl: string
}

/**
 * 利用側の追加 props 属性
 */
interface SecurityCheckProps {
    securityCheck: SecurityCheckParams
}

/**
 * 実装側の追加 props 属性
 */
interface CheckPassed {
    isCheckComplete: boolean
}
export interface CheckTargetFormProps extends CheckPassed {
    onSubmit(event): void
    formRef?: MutableRefObject<HTMLFormElement>
}
export interface CheckTargetLinkProps extends CheckPassed {
    onClick(event): void
}
export interface CheckAndActionProps extends CheckPassed {
    onAction(event, submittingContent?: string): void
}

const smsAuthModalTitle = "認証のない書き込みは制限されています"

const smsAuthStateCreator = smsAuthStoreCreatorFrom(window.globalParamsContainer.smsAuthParams)

/**
 * コンポーネントのアクションに対してsms認証、凍結/編集制限、ダブルクリック抑止のチェックを挟むようにする
 *
 * @param TargetComponent
 *
 * 使用方法
 *
 *  FooProps がコンポーネント固有の props 型だとして...
 *
 *  // FooProps & CheckedComponentProps を取るコンポーネントを作る
 *  function _Foo({
 *      children, ...props,
 *      onAction, isCheckComplete
 *  }: FooProps & CheckedComponentProps) {
 *      const formRef = useRef<HTMLFormElement>()
 *      // isCheckComplete によってチェック済みになったかどうか判断して挙動を作る
 *      useEffect(() => {
 *          if (isCheckComplete) {
 *              formRef.current?.submit()
 *          }
 *      }, [isCheckComplete])
 *      // onAction をアクション実行時の実体として利用した VNode を返す
 *      return <form onSubmit={onAction} ref={formRef} {...props}>
 *          {children}
 *      </form>
 *  }
 *
 *  // withSecurityCheck をかけると上記のコンポーネントが
 *  // FooProps & SecurityCheckProps を props に取るコンポーネントに化ける
 *  Foo = withSecurityCheck(_Foo)
 *
 *  // 固有の props にセキュリティチェック用のおまけを付けて使う
 *  ReactDOM.render(React.createElement(
 *      Foo,
 *      {
 *          ...fooProps,
 *          securityCheck,
 *      } as FooProps & SecurityCheckProps,
 *      children
 *  ), placeholderElement)
 *
 * // SecurityCheck の例
 * const securityCheck = {
 *     targetPage: self.data('page'),
 *     preCheckSms: false,
 *     checkSms: true,
 *     interruptType: 'none',
 *     unfreezeUrl: ''
 * }
 */
export default function withSecurityCheck<P>(
    TargetComponent: FunctionComponent<P & CheckAndActionProps>
): FunctionComponent<P & SecurityCheckProps> {
    return noPropCheckCreation(TargetComponent as FunctionComponent, 'onAction')
}

export function withFormSecurityCheck<P>(
    TargetComponent: FunctionComponent<P & CheckTargetFormProps>
): FunctionComponent<P & SecurityCheckProps> {
    return noPropCheckCreation(TargetComponent as FunctionComponent, 'onSubmit')
}

export function withLinkSecurityCheck<P>(
    TargetComponent: FunctionComponent<P & CheckTargetLinkProps>
): FunctionComponent<P & SecurityCheckProps> {
    return noPropCheckCreation(TargetComponent as FunctionComponent, 'onClick')
}

function noPropCheckCreation<P>(TargetComponent: FunctionComponent<P & CheckPassed>, actionPropName: string) {
    // 新たな関数コンポーネントを作成して返す
    return (
        {securityCheck, children, ...props}: PropsWithChildren<P & SecurityCheckProps>,
        // context?: any, FunctionComponent の型としてはこれがあるけどどうなんだろ?
    ) => {
        const {preCheckSms, checkSms, targetPage, interruptType, unfreezeUrl} = securityCheck
        const useSmsAuthStore = useLocalStateUseStore(smsAuthStateCreator)
        const {trollingDefenceCheck} = useSmsAuthStore()

        const useInterruptAlertStore = useLocalStateUseStore(interruptAlertStateCreator)
        const {setIsInterruptAlertOpen} = useInterruptAlertStore()

        const [isCheckComplete, setIsCheckComplete] = useState(false)

        // ダブルクリック抑止 state では間に合わない
        const submittingRef = useRef(false)

        useEffect(() => {
            const handler = (e) => {
                // 戻るボタンで戻ってきたときにロックを外すため
                if (e.persisted) {
                    submittingRef.current = false
                    setIsCheckComplete(false)
                }
            }

            window.addEventListener('pageshow', handler)

            return () => window.removeEventListener('pageshow', handler)
        }, [])

        useEffect(() => {
            if (preCheckSms) {
                trollingDefenceCheck(smsAuthModalTitle, () => {}, targetPage)
            }
        }, [])

        const handleSubmit = (event, submittingContent) => {
            // onSubmit と onClick の場合、submittingContent はつねに undefined になる
            event.preventDefault()
            if (!submittingRef.current) {
                if (interruptType === 'freeze' || interruptType === 'block') {
                    setIsInterruptAlertOpen(true)
                } else if (checkSms) {
                    // console.log(submittingContent)
                    trollingDefenceCheck(smsAuthModalTitle, () => {
                        submittingRef.current = true
                        setIsCheckComplete(true)
                    }, targetPage, submittingContent)
                }
            }
        }

        let actionProps = {}
        actionProps[actionPropName] = handleSubmit

        // FIXME @types/react >= 18 でゆるゆる FunctionComponent が通らなくなった
        // @ts-ignore
        const target = React.createElement(TargetComponent, {
            ...actionProps,
            isCheckComplete,
            ...props
        }, children)

        return <div className="checked-form">
            {target}
            {checkSms &&
                <SmsAuthModal useStore={useSmsAuthStore}/>
            }
            {(interruptType === 'freeze' || interruptType === 'block') &&
                <InterruptAlert interruptType={interruptType} unfreezeUrl={unfreezeUrl} useStore={useInterruptAlertStore}/>
            }
        </div>
    }
}
