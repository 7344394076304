import {useRef} from 'react'
import {create, StoreApi, UseBoundStore} from "zustand"
import {StateCreator} from 'zustand/vanilla'

export default function useLocalStateUseStore<T>(initializer: StateCreator<T>) {
    const storeRef = useRef<UseBoundStore<StoreApi<T>>>()
    if (!storeRef.current) {
        storeRef.current = create<T>()(initializer)
    }
    return storeRef.current
}
